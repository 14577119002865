.shm-result-tab{
  width: 100%;
}

.result-showcase-block{
  position: relative;

  .showcase-block-title{
    position: relative;
    padding: 0.5em 1em 0.5em 2.5em;
    color: #7d8389;
    //border-bottom: 1px solid #7d8389;
    background-color: #eff1f2;
    //margin-top: 1em;


    svg{
      display: block;
      position: absolute;
      font-size: 1em;
      top: 0.5em;
      left:1em;
      width: 1em;
      height: 1em;
      path{
        fill: #7d8389;
      }
    }
    h3{
      line-height: 100%;
      position: relative;
      display: block;
      font-size: 1em;
      margin: 0;
    }
  }

  .showcase-block-content{

  }
}

.reset-button{

}