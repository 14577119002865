@import "./infoBox";
@import "./util";

:root {
    --sidebar_width: 48px;
    --header_height: calc(48px + 6px);
}

body {
    margin: 0;
    padding: 0;
    height: 100vh;
}

#root {
    width: 100%;
    height: 100%;
    z-index: 1;
    display: grid;
    grid-template-areas:
        "sidebar header"
        "sidebar main";

    grid-template-rows: var(--header_height) auto;
    grid-template-columns: 0 100%;

    @media (min-width: 1194px) {
        grid-template-columns: var(--sidebar_width) calc(100% - (var(--sidebar_width)));
    }

    gap: 0;
}

main {
    grid-area: main;
    overflow-y: auto;
    overflow-x: hidden;

    padding: 0 1rem 1rem 1rem;

    @media (min-width: 768px) {
        padding: 0 2rem 2rem 2rem;
    }

    &.no_padding {
        padding: 0;
    }

    &.svg {
        overflow: hidden;
    }

    &#maps_site {
        overflow: hidden;
    }
}

.drag_handle {
    width: 20px;
    height: 20px;
    background-color: var(--secundary_background_color);
    cursor: move;
    /* positioning of handle above content */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

span.react-resizable-handle:after,
.drag_handle img {
    width: 100%;
    filter: var(--filter_to_text_color);
}

/* reset layout button */
#add,
#reset {
    position: fixed;
    bottom: 20px;
    right: 50px;
    width: 40px;
    height: 40px;

    border-radius: 15px;

    padding: 0;
    margin: 0;
    border: none;
    background-color: var(--text_color);

    z-index: 1;
    opacity: 0.25;

    transition: 0.3s;

    img {
        filter: invert(100%);
    }

    &:hover {
        width: 50px;
        height: 50px;
        opacity: 1;
        cursor: pointer;

        .tooltip {
            visibility: visible;
            opacity: 1;
        }
    }

    .tooltip {
        left: -250%;

        &::after {
            left: 100%;
            transform: rotate(180deg);
        }
    }

    img {
        width: 100%;
    }
}

#loading_site {
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

/* Popups */
.popup {
    &-arrow {
        display: none;
    }

    &-overlay {
        background-color: rgba(111, 117, 123, 0.251);
        -webkit-backdrop-filter: blur(0.25rem);
        backdrop-filter: blur(0.25rem);
    }

    &-content {
        // copy the rule for dialog[open]
        .m-dialog {
            --max-dialog-width: 44rem;
            border: 0;
            color: var(--plain__enabled__front__default);
            display: grid;
            grid-template-areas:
                "remark "
                "header "
                "divider"
                "content";
            grid-template-columns: minmax(auto, var(--max-dialog-width));
            grid-template-rows: repeat(3, auto) 1fr;
            max-height: calc(100vh - 4rem);
            max-width: var(--max-dialog-width);
            overflow: hidden;
            padding: 0;
        }
    }
}

.error_field {
    color: var(--bosch-red) !important;
}

.m-dialog__body.form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.a-notification {
    &.a-notification--banner {
        z-index: 300;
        position: fixed;
        height: 4rem;
        top: calc(100vh - 4rem);
    }

    &:not(.a-notification--banner) {
        .a-button.a-button--integrated {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

button.a-password-input__icon-password {
    position: absolute;
    right: 0.25rem;
    bottom: 0.25rem;
    border: none;
    background: none;
    cursor: pointer;
}

.select {
    position: relative;

    .control {
        .container {
            row-gap: 0.5rem;
            column-gap: 1rem;

            .item {
                position: relative;
                margin: 0;

                > div.multivaluelabel {
                    padding-right: 1.875rem;
                }

                > div.multivalueremove {
                    position: absolute;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    background: none;
                    justify-content: end;
                }
            }

            .indicators {
                align-items: flex-start;

                > * {
                    margin-top: 0.25rem;
                }

                > .clear::after {
                    content: "";
                    width: 0.0625rem;
                    height: 1.25rem;
                    display: inline-block;
                    margin: 0 0.25rem;
                    border-left: 1px solid var(--plain__enabled__front__default);
                }
            }
        }
    }
    .menu {
        position: absolute;
        background: var(--background);

        .option {
            padding: 1rem 0;
            cursor: pointer;
            user-select: none;

            .a-checkbox {
                margin: 0.75rem 1rem;
            }

            &.selected {
                background: var(--minor-signal-neutral__enabled__fill__default);
                color: var(--minor-signal-neutral__enabled__front__default);
            }
            &.focused {
                background: var(--minor-signal-neutral__enabled__fill__hovered);
                color: var(--minor-signal-neutral__enabled__front__hovered);

                &.selected {
                    background: var(--minor-signal-neutral__enabled__fill__pressed);
                    color: var(--minor-signal-neutral__enabled__front__pressed);
                }
            }
            &.disabled {
                background: var(--neutral__disabled__fill__default);
                color: var(--neutral__disabled__front__default);
                cursor: not-allowed;
            }
        }
    }
}

i.bosch-ic {
  font-family: Bosch-Icon, sans-serif !important;
}