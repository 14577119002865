/* Fraction */
.fraction {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
}

.numerator {
    display: block;
    border-bottom: 1px solid #000;
    padding-bottom: 2px;
}

.denominator {
    display: block;
    padding-top: 2px;
}

/* ----------------- */

/* Text */

.text-align-right {
    text-align: right;
}

/* ----------------- */

/* Size */

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

/* ----------------- */

/* Flexbox */

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-align-center {
    align-items: center;
}

.flex-align-end {
    align-items: flex-end;
}

.flex-justify-items-center {
    justify-items: center;
}

.flex-justify-content-center {
    justify-content: center;
}

.flex-justify-content-end {
    justify-content: flex-end;
}

.flex-justify-content-start {
    justify-content: flex-start;
}

/* --------------------- */

/* Padding */

.pt-05 {
    padding-top: 0.5rem;
}

.pb-05 {
    padding-bottom: 0.5rem;
}

.pt-1 {
    padding-top: 1rem;
}

.pb-1 {
    padding-bottom: 1rem;
}

.pt-15 {
    padding-top: 1.5rem;
}

.pb-15 {
    padding-bottom: 1.5rem;
}

/* --------------------- */

/* Margin */

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: 1rem;
}

.ml-05 {
    margin-left: 0.5rem;
}

/* --------------------- */