#navigation {
    #mobile_expand {
        @media (min-width: 1194px) {
            display: none;
        }
    }

    nav {
        z-index: 150;
        text-overflow: clip;
        max-height: 100vh;
        overflow-y: auto;
        scrollbar-width: thin;

        & + * + .o-minimal-header__top {
            padding-left: 0;

            @media (min-width: 1194px) {
                padding-left: 3rem;
            }
        }

        &.-open {
            width: 22rem;

            & + * + .o-minimal-header__top {
                @media (min-width: 1194px) {
                    padding-left: 22rem;
                }
            }
        }

        .a-icon {
            img {
                width: 24px;
                height: 24px;
            }
        }

        &.-contrast {
            .a-icon {
                img {
                    filter: invert(1);
                }
            }

            .-selected {
                .a-icon {
                    img {
                        filter: invert(0);
                    }
                }
            }
        }

        ul > li.m-side-navigation__menuItem {
            .m-side-navigation__label {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: clip;
            }
        }

        ul.m-side-navigation__menuItems > li.m-side-navigation__menuItem > ul.m-side-navigation__menuSubitems > li.m-side-navigation__menuSubitem > a.m-side-navigation__link {
            // indent second level links
            padding-left: 36px;

            i:last-of-type {
                // bugfix for rotated icons
                transform: none;
            }
        }
    }

    .o-minimal-header__supergraphic {
        z-index: 200;
    }

    header {
        white-space: nowrap;

        .o-minimal-header__burger {
            position: absolute;
            left: 0;

            @media (min-width: 768px) {
                position: initial;
                left: initial;
            }
        }

        .o-minimal-header__title {
            display: none;

            @media (min-width: 768px) {
                display: block;
            }
        }

        &.o-minimal-header__top .o-minimal-header__actions {
            display: flex;
        }

        span.a-button {
            cursor: initial;
        }

        #header_weather {
            display: none;

            @media (min-width: 1194px) {
                display: list-item;
            }
        }

        #header_clock {
            display: none;

            @media (min-width: 1194px) {
                display: list-item;
            }
        }

        #weather_header {
            margin-left: 1rem;
            margin-right: 1rem;

            span {
                display: inline-block;
                margin-right: 1rem;
            }

            #weather_degree {
                width: 50px;
            }

            #weather_location {
                width: 100px;
            }
        }

        #car_id {
            width: 200px;
        }
    }
}
