#history_site {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    #filters {
        padding-bottom: 12px;
        border-top: 0;

        .a-accordion__headline-button {
            transform: rotate(0);
        }

        &.a-accordion--open {
            .a-accordion__headline-button {
                transform: rotate(180deg);
            }
        }

        div.a-accordion__headline {
            align-items: center;
            cursor: initial;

            > div {
                display: flex;
                flex-wrap: wrap;
                justify-content: baseline;

                width: calc(100% - 36px);

                > div {
                    margin: 0 1rem 0.5rem 0;
                    width: 100%;
                    max-width: 30rem;

                    @media (min-width: 768px) {
                        width: calc(50% - 1rem);
                    }

                    @media (min-width: 1194px) {
                        width: 15rem;
                    }
                }
            }
        }

        div.a-accordion__content {
            padding-bottom: 12px;

            #filter_measurement_api,
            #filter_field_api {
                > label {
                    display: inline-block;
                    width: 100%;
                }
            }

            #filter_field_api {
                margin-top: 12px;
            }
        }

        div.a-notification {
            margin-bottom: 12px;
        }
    }

    #data_container {
        width: 100%;
        min-height: calc(100% - 530px);
        height: 100%;
        max-height: 100%;

        #plot_container {
            display: flex;
            height: 100%;
            max-width: 100%;
        }

        #loading_field {
            position: absolute;
            top: var(--header_height);
            left: var(--sidebar_width);
            width: calc(100vw - var(--sidebar_width));
            height: calc(100vh - var(--header_height));

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            z-index: 1;

            p {
                color: var(--bosch-red);
            }
        }
    }
}
