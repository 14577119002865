.cvem {
    > #shm {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 2%;
        padding: 0 1em;

        .side {
            //flex: 1 0 30rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            flex-grow: 1;
            min-width: 49%;

            .a-box {
                flex: 1 0;
                min-height: 0;

                &.mode {
                    flex-basis: calc((100vh - 10rem) / 4 - 1rem);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 2rem;
                    padding: 1rem;

                    .name {
                        flex: 0.25 0 6rem;
                        font-size: 1.5rem;
                        line-height: 1.4;
                        font-weight: 700;
                    }

                    .properties {
                        flex: 1 0 6rem;
                        text-align: center;

                        &.wide {
                            flex: 1 0 14rem;
                        }

                        i.a-icon {
                            font-size: 2rem;
                        }
                    }

                    .toggle {
                        flex: 0 0 72px;
                        text-align: center;

                        .a-toggle {
                            .a-toggle__trigger {
                                transform: scale(1.5);
                            }
                        }
                    }
                }
            }

            &.info-card {
                padding: 1rem;
                &.showcase-card{
                    padding: 0;
                    min-height: calc( 100vh - 9.5em);
                    //min-width: 50%;
                    .showcase-header{
                        padding: 1em;
                        background: #e0e2e5;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .showcase-header-title{
                            font-size: 1.5em;
                        }
                        .recording-text{
                            &:before{
                                content:"";
                                display:inline-block;
                                font-size: 1em;
                                width: 1em;
                                height: 1em;
                                margin-right: 0.25em;
                                border-radius: 50%;
                                background: red;
                            }
                        }
                    }

                    &.show-case-result-card{
                        min-width: 32%;
                        flex-grow: 1;
                        display: block;
                    }
                    .showcase-content{
                        margin: 1rem;
                        height: 100%;
                    }
                }


                .showcase-row{
                    display: flex;
                    padding: 0.75em 0;
                    border-bottom: 1px solid gray;

                    &:last-child {
                        border: none;
                    }

                    .left-col{
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        width: 12em;
                        //background: yellow;
                        padding: 1em  2em 1em 1em;
                        .showcase-block-icon{
                            font-size: 3.75em;
                        }
                        .show-case-title{
                            font-size: 1em;
                            margin: 0.75em 0 0 0;
                            text-align: center;
                        }
                    }

                    .right-col{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 100%;
                        //background: lightgreen;

                    }
                }



                .info-row{
                    .info-box{
                        padding: 1em;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        height: 100%;
                        text-align: center;
                    }
                    i.a-icon {
                        font-size: 4rem;
                    }
                    h4{
                        margin:0;
                        font-size: 0.75em;
                    }
                    p{
                        margin: 0;
                    }

                }



            }
        }
    }
}