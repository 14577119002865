svg {
    text {
        font-family: boschsans, "Bosch Sans", "Bosch Office Sans", sans-serif;
    }
}

#tmm_page {
    svg {
        text {
            fill: #000;
        }

        #measurement_text {
            text {
                fill: #f00;
                font-family: monospace;
            }
        }

        .moving_line {
            stroke-dasharray: 10;
            animation: dash_thermo 10s infinite linear;
            stroke-width: 3px;
        }

        @keyframes dash_thermo {
            to {
                stroke-dashoffset: 100;
            }
        }

        .rotating_fan {
            width: 0px;
            height: 0px;
            animation: rotation 2s infinite linear;
        }

        @keyframes rotation {
            from {
                transform: rotate(0deg);
                transform-box: fill-box;
                transform-origin: center;
            }

            to {
                transform: rotate(359deg);
                transform-box: fill-box;
                transform-origin: center;
            }
        }

        .pumping {
            animation: 1s ease-in-out infinite alternate pump_thermo;
        }

        @keyframes pump_thermo {
            from {
                opacity: 0.5;
            }

            to {
                opacity: 1;
            }
        }
    }
}

.-dark-mode {
    #tmm_page {
        svg {
            text {
                fill: #fff;
            }

            #measurement_text {
                text {
                    fill: #ff0;
                }
            }

            .bw,
            .bw text {
                filter: invert(1);
            }
        }
    }
}

#hvpowernet_page,
#heatflow_page {
    .heatflow-header {
        display: flex;
        align-items: center;
        margin-left: 1rem;
        margin-top: 1rem;

        .legend {
            display: flex;
            align-items: center;
            gap: 5px;
            height: 100%;
            margin-left: 2rem;
        }

        .legend-red {
            width: 20px;
            height: 20px;
            background-color: var(--bosch-red-60);
            margin-right: 5px;
        }

        .legend-blue {
            width: 20px;
            height: 20px;
            background-color: var(--bosch-blue-60);
            margin-right: 5px;
        }
    }

    svg {
        .invisible {
            display: none;
        }

        text {
            fill: var(--plain__enabled__front__default);
            text-anchor: middle;
            dominant-baseline: middle;

            &.measurement {
                font-size: 14px;
                font-family: Consolas, "Lucida Console", "Courier New", Courier, monospace;
                font-weight: bold;
            }

            &.align_left {
                text-anchor: start;
            }

            &.align_right {
                text-anchor: end;
            }
        }

        .component {
            .body {
                fill: var(--bosch-blue-50);
            }

            .dark-body {
                fill: var(--bosch-blue-35);
            }

            .secondary-body {
                fill: var(--neutral__enabled__fill__default);
            }

            text.current-color {
                fill: currentColor;
            }

            text {
                fill: var(--bosch-white);
                text-shadow: 0 0 0.5px var(--bosch-black);

                &.title {
                    font-size: 18px;
                    font-weight: bold;
                }

                &.expired {
                    fill: var(--bosch-gray-60);
                }
            }
        }

        .arrow_label {
            .body {
                fill: var(--background);
                stroke: var(--bosch-blue-50);
                stroke-width: 1px;
            }

            text {
                &.expired {
                    fill: var(--plain__disabled__front__default);
                }
            }
        }

        .arrow {
            stroke: var(--small__enabled__fill__pressed);

            &.hot {
                stroke: var(--bosch-red-60);
            }

            &.cold {
                stroke: var(--bosch-blue-60);
            }

            transition: stroke-width 0.5s ease-in-out;

            &.expired {
                stroke: var(--plain__disabled__front__default);
            }

            &.unknown {
                stroke-dasharray: 5;
            }

            &.zero {
                stroke-dasharray: 1;
            }
        }

        .arrowhead_background {
            fill: var(--background);
        }

        .icon-current-color {
            fill: currentColor;
        }
    }
}

#thermal_management_page {
    svg {
        text {
            fill: var(--plain__enabled__front__default);
            text-anchor: middle;
            dominant-baseline: middle;

            &.align_left {
                text-anchor: start;
            }

            &.align_right {
                text-anchor: end;
            }
        }

        .component {
            .body {
                fill: var(--bosch-blue-50);
                stroke: var(--background);
                stroke-width: 2px;

                &.gray {
                    fill: var(--bosch-gray-70);
                    stroke: var(--plain__enabled__front__default);
                }
            }

            .port {
                fill: var(--bosch-blue-35);
                stroke: var(--background);
                stroke-width: 2px;
            }

            text {
                fill: var(--bosch-white);
                text-shadow: 0 0 0.5px var(--bosch-black);

                &.title {
                    font-size: 20px;
                    font-weight: bold;
                }

                &.measurement {
                    font-size: 17.5px;
                    font-family: Consolas, "Lucida Console", "Courier New", Courier, monospace;
                }

                &.label {
                    fill: var(--bosch-gray-90);
                    font-size: 16px;
                }

                &.expired {
                    fill: var(--bosch-gray-60);
                }

                &.gray {
                    fill: var(--plain__enabled__front__default);
                    stroke: none;
                    font-weight: normal;
                    text-shadow: none;
                }
            }
        }

        .gray {
            fill: var(--bosch-gray-70);
            stroke: var(--plain__enabled__front__default);
        }

        .arrow {
            fill: none;
            stroke: var(--bosch-blue-35);
            stroke-width: 5px;

            &.thin {
                stroke-width: 1px;
            }

            &.green {
                stroke: var(--bosch-green-60);
            }
        }

        .arrow_fill {
            stroke: none;
            fill: var(--bosch-blue-35);
        }

        .arrowhead_background {
            fill: var(--background);
        }

        .arrowhead_head {
            fill: var(--bosch-blue-35);
        }

        .fan.animate {
            animation: radiator_fan_animate 3s ease-in-out infinite;
        }

        @keyframes radiator_fan_animate {
            0% {
                transform: scale(1, 1);
                transform-box: fill-box;
                transform-origin: center;
            }

            100% {
                transform: scale(1, -1);
                transform-box: fill-box;
                transform-origin: center;
            }
        }
    }
}

.-dark-mode {
    #ph_diagram_page {
        svg {
            filter: invert(1);
        }
    }
}

.-expired {
    circle {
        filter: saturate(0);
    }
}
