#settings_site {
    .a-tab-navigation {
        padding: 0;
    }

    div[data-tab] {
        display: none;

        &:first-of-type {
            display: block;
        }

        > section {
            flex-basis: 40rem;
            flex-shrink: 0;
            flex-grow: 1;

            table {
                width: 100%;

                thead,
                th {
                    font-weight: bold;
                }
            }

            button {
                margin-right: 1rem;

                &:last-of-type {
                    margin-right: 0;
                }
            }

            .settings_block {
                table {
                    td:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }

    .a-toggle {
        label {
            -webkit-user-select: none;
            user-select: none;
        }
    }
}
