/* reset layout button */
#add {
    right: 105px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 70%;
    }
}

#filter_div {
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

    max-height: 300px;
    overflow-y: auto;

    margin-bottom: 10px;

    > div {
        display: flex;
        flex-direction: column;

        padding-left: 50px;

        .filter {
            position: relative;
            display: flex;

            align-items: center;

            margin-bottom: 10px;

            font-size: 19px;

            /* idea and suggestions: https://www.w3schools.com/howto/howto_css_custom_checkbox.asp */
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked ~ .checkmark {
                    background-color: var(--primary_accent_color);

                    &:after {
                        display: block;
                    }
                }
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: -40px;
                height: 25px;
                width: 25px;

                cursor: pointer;
                opacity: 0.75;

                background-color: var(--primary_background_color);

                &:after {
                    content: "";
                    display: none;

                    position: absolute;
                    top: 5px;
                    left: 9px;

                    width: 5px;
                    height: 10px;

                    border: solid var(--text_color);
                    border-width: 0 3px 3px 0;

                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }

            &:hover input ~ .checkmark {
                opacity: 1;
            }
        }
    }
}
