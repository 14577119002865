.signal-row {
  font-weight: 700;
  width: 100%;
  padding: 0.5em;

  .signal-value {
    display: inline-block;
    font-size: 2.25em;
    width: 50%;
    color: inherit;
    span{
      float: right;
      color: inherit;
      margin-right: 0.25em;
      line-height: 0.6;
    }
  }

  .signal-postfix-title {
    display: inline-block;
    font-size: 1em;
    line-height: 1;
    color:inherit;
    width: 50%;
  }

  &.dark{
    font-size: 1.5em;
    background-color:#e8f1ff;
    color: #007bc0;
  }
}