.no-data-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height:100%;
    color:#8a9097;
    background-color: white;
    text-align: center;
    padding: 1em;

    &.large{
    font-size: 2em;
    }

    .no-data-icon {
        font-size: 4em;
    }

    .no-data-text {
        font-size: 1.5em;
        margin-top: 0.25rem;
    }
}


