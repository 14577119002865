#login_site {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;

    width: 24rem;
    margin: 4rem auto 1rem;

    grid-row-start: sidebar;
    grid-row-end: main;
    grid-column-start: sidebar;
    grid-column-end: main;

    > div {
        width: 22rem;

        &.right {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;
            gap: 1rem;
        }
    }

    #login_logo {
        height: 200px;
    }
}
