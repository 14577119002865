.a-toggle {
  label {
    display: flex;
  }

  input[type='checkbox'] {
    appearance: none;
    height: 0;
    width: 0;

    &:checked ~ .a-toggle__trigger {
      background-color: var(--major-accent__enabled__fill__default);

      &::before {
        background-color: var(--major-accent__enabled__front__default);
        left: 1.875rem;
      }
    }

    &:focus-visible {
      outline: none;

      & ~ .a-toggle__trigger {
        //@include focus-outside;
        --focus-border-radius: 0.75rem;
      }
    }

    &:disabled {
      & ~ .a-toggle__trigger,
      & ~ .a-toggle__label {
        pointer-events: none;
      }

      & ~ .a-toggle__trigger {
        background-color: var(--small__disabled__fill__default);

        &::before {
          background-color: var(--major-accent__disabled__front__default);
        }
      }

      & ~ .a-toggle__label {
        color: var(--plain__disabled__front__default);
      }

      &:checked ~ .a-toggle__trigger {
        background-color: var(--major-accent__disabled__fill__default);
      }
    }
  }

  &__label {
    color: var(--plain__enabled__front__default);
    cursor: pointer;
    font-size: 1rem;

    &--left {
      margin-right: 0.5rem;
    }

    &--right {
      margin-left: 0.5rem;
    }
  }

  &__trigger {
    background-color: var(--small__enabled__fill__default);
    border-radius: 0.75rem;
    cursor: pointer;
    height: 1.5rem;
    width: 3rem;
    position: relative;

    &::before {
      background-color: var(--small__enabled__front__default);
      border-radius: 0.625rem;
      content: '';
      height: 0.75rem;
      left: 0.375rem;
      position: absolute;
      top: 0.375rem;
      transition: left 0.2s ease-out, background-color 0.1s ease-out;
      width: 0.75rem;
    }
  }

  &:hover {
    .a-toggle__trigger {
      background-color: var(--small__enabled__fill__hovered);
    }

    input[type='checkbox']:checked:not(:disabled) ~ .a-toggle__trigger {
      background-color: var(--major-accent__enabled__fill__hovered);
    }
  }

  &:active {
    .a-toggle__trigger {
      background-color: var(--small__enabled__fill__pressed);
    }

    input[type='checkbox']:checked:not(:disabled) ~ .a-toggle__trigger {
      background-color: var(--major-accent__enabled__fill__pressed);
    }
  }
}