.info-box {
    padding: 1rem;

    .title {
        font-size: 1.5rem;
        font-weight: 600;

        .a-icon {
            font-size: 1.5rem;
            font-weight: 600;
        }
    }
}