.slider-overrideable{
  .titles{
    position: relative;
    display:flex;
    justify-content: space-between;

    label{
      font-size: 1em;
    }
  }

  .slider-container {
    position: relative;
    width: 100%;
  }

  .slider {
    --min-slider-percent: 5%;
    --max-slider-percent: 95%;

    -webkit-appearance: none;
    width: 100%;
    height: 1em;
    background: linear-gradient(to right,
            #e0e2e5 0%,
            #e0e2e5 var(--min-slider-percent),
            #0088d4 var(--min-slider-percent),
            #0088d4 var(--max-slider-percent),
            #ff2124 var(--max-slider-percent),
            #ff2124 100%);
    border-radius: 0.25em;
    outline: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 1em;
      height: 1em;
      background-color: white;
      border: 2px solid black;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
    }

    &::-moz-range-thumb {
      width: 2em;
      height: 2em;
      background-color: white;
      border: 2px solid black;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .slider-thumb {
    position: absolute;
    top: -0.5em;
    font-size: 1em;
    border: 2px solid #004975;
    color: #003253;
    background-color: #e8f1ff;
    border-radius: 0.5em;
    padding: 0.3em;
    width: 5em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none; // ensure it's not interactable

    &.gray{
      color: #383b3e;
      background-color: #eff1f2;
      border-color: #43464a;
    }

    &.red{
      color: #680001;
      background-color: #ffb2b2;
      border-color: #920002;
    }
  }
}